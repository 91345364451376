export const plants = [
  {
    id: 1,
    name: "Aloe Vera",
    price: 10,
    category: "Succulent",
    thumbnail: "assets/aloe-vera.jpg",
  },
  {
    id: 2,
    name: "Snake Plant",
    price: 15,
    category: "Succulent",
    thumbnail: "assets/snake-plant.jpg",
  },
  {
    id: 3,
    name: "Monstera",
    price: 20,
    category: "Tropical",
    thumbnail: "assets/monstera.avif",
  },
  {
    id: 4,
    name: "Peace Lily",
    price: 12,
    category: "Tropical",
    thumbnail: "assets/peace-lily.webp",
  },
  {
    id: 5,
    name: "Cactus",
    price: 8,
    category: "Succulent",
    thumbnail: "assets/cactus.jpg",
  },
  {
    id: 6,
    name: "Fiddle Leaf Fig",
    price: 25,
    category: "Tropical",
    thumbnail: "assets/fiddle-leaf-fig.jpg",
  },
  {
    id: 7,
    name: "Spider Plant",
    price: 10,
    category: "Air Purifying",
    thumbnail: "assets/spider-plant.jpg",
  },
  {
    id: 8,
    name: "Pothos",
    price: 14,
    category: "Air Purifying",
    thumbnail: "assets/pothos.jpg",
  },
  {
    id: 9,
    name: "Rubber Plant",
    price: 18,
    category: "Tropical",
    thumbnail: "assets/Rubber-Plant.webp",
  },
];
